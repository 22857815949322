import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyBvGMB5QFOfj6NjPDQSBZ9fu8XYokIpSA4',
    authDomain: 'techrepair-sw-canada.firebaseapp.com',
    projectId: 'techrepair-sw-canada',
    storageBucket: 'techrepair-sw-canada.appspot.com',
    messagingSenderId: '120361292390',
    appId: '1:120361292390:web:8b260223e84992374853a7',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();
export { firebase };
