import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChild, faTasks, faCogs, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const tabs = [
    {
        route: '/app/status',
        icon: faChild,
        label: 'Status',
    },
    {
        route: '/app/orders',
        icon: faTasks,
        label: 'Jobs',
    },
    {
        route: '/app/vacation',
        icon: faCalendarAlt,
        label: 'Schedule',
    },
    {
        route: '/app/settings',
        icon: faCogs,
        label: 'Settings',
    },
];

const Navigation = () => (
    <div>
        <section id="bottom-navigation" className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow bottom-tab-nav">
            <div id="tabs" className="flex justify-between py-2">
                {tabs.map((tab) => (
                    <NavLink
                        to={tab.route}
                        key={tab.label}
                        className="w-full focus:text-teal-500 hover:text-teal-500 text-gray-700 justify-center inline-block text-center pt-2 pb-1 bottom-nav-link"
                    >
                        <FontAwesomeIcon size="lg" icon={tab.icon} />
                        <span className="tab tab-account block text-xs bottom-tab-label">{tab.label}</span>
                    </NavLink>
                ))}
            </div>
        </section>
    </div>
);

export default Navigation;
